@import "~assets/styles/1_settings/_settings-project";

.c-cell {
  font-size: $small-font-size;
  width: 33.33%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  // padding-bottom: $multiplier-default * 0.5;

  font-size: $default-font-size;

  &--bold {
    font-weight: $font-bold;
  }

  &--big {
    padding: $multiplier-default 0;
  }

  &--align-to-left {
    flex-direction: row;
  }

  &--supplement {
    color: $green;
  }

  &--charge {
    color: $red;
  }
}
