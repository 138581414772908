@import "~assets/styles/1_settings/_settings-project";

.c-buttons-group-input {
  margin-bottom: $multiplier-default;

  &__error {
    margin-top: $multiplier-default * 0.5;
    height: $multiplier-default;
    font-size: $small-font-size;
    line-height: $multiplier-default;
    color: $red;
  }
}
