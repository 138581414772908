@import "~assets/styles/1_settings/_settings-project";

.c-filters {
  position: relative;
  display: flex;
  margin-right: $multiplier-default * 5;

  &__source-checkboxes {
    position: absolute;
    display: flex;
    width: 100%;
    top: $multiplier-default * 4 + 2px;
    margin-top: 1px;
    justify-content: space-around;
    padding: $multiplier-default $multiplier-default * 0.5;
    border-radius: $multiplier-default * 0.25;
    background-color: $accent-black;
  }

  &__button {
    width: $multiplier-default * 11;
  }
}
