@import "~assets/styles/1_settings/_settings-project";

.c-user {
  position: relative;

  &__icon {
    height: $multiplier-default * 3;
    width: $multiplier-default * 3;
    background-color: $gray;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }

  &__icon-picture{
    width: 100%;
    height: 100%;    
  }

  &__modal{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;    
    top: $multiplier-default * 4;
    right: 0;
    background-color: $light-gray;
    padding: $multiplier-default * 2;
    border-radius: $multiplier-default * 0.25;
    box-shadow: $box-shadow-default;    
    z-index: 999;
  }

  &__modal-icon{
    height: $multiplier-default * 8;
    width: $multiplier-default * 8;
    background-color: $gray;
    border-radius: 50%;
    overflow: hidden;
  }

  &__name{
    padding-top: $multiplier-default * 2;
  }

  &__email{
    padding-top: $multiplier-default;
  }

  &__sign-out{
    margin-top: $multiplier-default * 2;
    padding: $multiplier-default $multiplier-default*7;
    color: $primary-black;
    border: solid 1px $primary-black;
    text-transform: uppercase;
    white-space: nowrap;
    border-radius: $multiplier-default * 0.25;
    font-weight: $font-bold;
    cursor: pointer;
  }
}
