@import "~assets/styles/1_settings/_settings-project";

.c-employee-management-header{
    display: flex;
    justify-content: flex-start;
    padding-bottom: $multiplier-default*2;

    &__button{
        flex-basis: 0;
        padding: $multiplier-default $multiplier-default*2;
        white-space: nowrap;
    }
}