@import "~assets/styles/1_settings/_settings-project";

.c-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: $multiplier-default 0;
  padding: $multiplier-default 0;
  background-color: $primary-black;
  color: $white;
  border-radius: $default-border-radius;
  box-shadow: $box-shadow-default;
  text-align: center;
  text-transform: uppercase;
  font-weight: $font-bold;
  line-height: 12px;
  letter-spacing: 1px;

  cursor: pointer;

  transition: $transition-default;

  &:hover {
    background: $light-black;
  }

  &__icon {
    padding: 0 $multiplier-default;
    font-size: $multiplier-default * 1.5;
  }

  &--disabled {
    cursor: not-allowed;
    background-color: $dark-gray;

    &:hover {
      background: $dark-gray;
    }
  }

  &--cancel {
    background-color: $white;
    color: $primary-black;
    padding: ($multiplier-default - 1px) 0;
    border: 1px solid $primary-black;

    &:hover {
      color: $white;
    }
  }

  &--danger {
    background-color: $red;

    &:hover {
      background-color: $red;
    }
  }
}
