@import "~assets/styles/1_settings/_settings-project";

.c-extra-charges-and-supplements {
  padding-top: $multiplier-default * 2;

  &__h4 {
    padding-bottom: $multiplier-default;
  }

  &__type {
    padding-top: $multiplier-default * 2;
    max-width: $multiplier-default * 20;
    align-items: flex-start;
  }

  &__description {
    padding-top: $multiplier-default * 2;
    flex-direction: column;
    align-items: flex-start;
  }

  &__description-row {
    margin-top: $multiplier-default * 0.5;
    color: $light-black;
  }
}
