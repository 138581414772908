@import "~assets/styles/1_settings/_settings-project";

.c-field {
  $self: &;
  display: flex;
  flex-direction: column;
  min-width: 30%;
  height: 100%;
  width: 90%;
  font-size: $small-font-size;
  line-height: $small-line-height;

  &__label {
    flex-grow: 1;
    text-align: right;
    padding-bottom: $multiplier-default * 0.5;
    padding-right: $multiplier-default;
    font-size: $small-font-size;

    #{$self}--align-to-left & {
      text-align: left;
    }
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: $multiplier-default;
    border-bottom: 1px solid $gray;
    color: $primary-black;
    font-weight: $font-bold;

    #{$self}--disabled & {
      border-bottom: none;
      border-radius: $default-border-radius;
      background-color: $gray;
      color: $black;
      font-weight: $font-regular;
    }

    #{$self}--positive & {
      color: $green;
    }

    #{$self}--negative & {
      color: $red;
    }

    #{$self}--align-to-left & {
      justify-content: flex-start;
    }
  }

  &__additional-value {
    width: 100%;
    text-align: right;
    padding-top: $multiplier-default * 0.25;
    padding-right: $multiplier-default;
  }
}
