@import "~assets/styles/1_settings/_settings-project";

.c-employee-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $multiplier-default * 2 0;
  padding-bottom: $multiplier-default * 4;

  &__right-items {
    display: flex;
  }

  &__sync-button {
    margin: 0;
    margin-left: $multiplier-default * 5;
    font-size: $multiplier-default * 2;
  }
}
