@import "~assets/styles/1_settings/_settings-project";

.c-month-modal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: $multiplier-default * 5;
  font-size: $default-font-size;
  font-weight: $font-regular;
  background-color: $white;
  box-shadow: $box-shadow-default;
  color: $black;
  cursor: default;
  z-index: 999;

  &__year-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__year-label {
    font-size: $big-font-size;
    font-weight: $font-bold;
    line-height: $big-line-height;
    user-select: none;
  }

  &__year-arrow {
    padding: $multiplier-default $multiplier-default * 2;
    cursor: pointer;

    &--disabled {
      cursor: default;
      color: $dark-gray;
    }
  }

  &__months-wrapper {
    width: $multiplier-default * 33;
    display: flex;
    flex-wrap: wrap;
    margin-left: $multiplier-default;
    margin-top: $multiplier-default;
  }

  &__month {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    height: $multiplier-default * 3;
    margin-right: $multiplier-default;
    margin-bottom: $multiplier-default;
    min-width: $multiplier-default * 10;
    background-color: rgba($light-black, 0.05);
    transition: $transition-default;
    line-height: $default-line-height;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: rgba($light-black, 0.15);
    }

    &--active {
      background-color: $light-black;
      color: $white;

      &:hover {
        background-color: $light-black;
      }
    }

    &--disabled {
      cursor: unset;
      color: $dark-gray;
      background-color: $light-gray;

      &:hover {
        background-color: $light-gray;
      }
    }
  }
}
