@import "~assets/styles/1_settings/_settings-project";

.c-date-picker {
  $self: &;
  margin-bottom: $multiplier-default;

  .react-datepicker-wrapper {
    width: 100%;
  }

  &__label {
    padding-left: $multiplier-default;
    padding-bottom: $multiplier-default * 0.5;
    font-size: $small-font-size;
    line-height: $small-line-height;
  }

  &__input-wrapper {
    position: relative;
    cursor: pointer;
  }

  &__input {
    width: 100%;
    padding: $multiplier-default;
    border-radius: $multiplier-default * 0.25;
    border: 1px solid $gray;
    cursor: pointer;
  }

  &__icon-wrapper {
    position: absolute;
    top: $multiplier-default;
    right: $multiplier-default * 1.5;
  }

  &__error {
    margin-top: $multiplier-default * 0.5;
    height: $multiplier-default;
    font-size: $small-font-size;
    line-height: $multiplier-default;
    color: $red;
  }
}
