@import "~assets/styles/1_settings/_settings-project";

.c-settings-modal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: $multiplier-default * 50;
    padding: $multiplier-default * 2;
    background-color: $light-gray;

    h2 {
      font-size: $multiplier-default * 3;
    }
  }

  &__done-button {
    width: unset;
    padding: $multiplier-default;
  }

  &__content {
    text-align: left;
    width: $multiplier-default * 45;
  }

  &__option {
    padding-bottom: $multiplier-default * 2;
  }
}
