@import "~assets/styles/1_settings/_settings-project";

.c-dates {
  width: 100%;
  padding: $multiplier-default * 2;

  &__field {
    margin-right: $multiplier-default * 2;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
