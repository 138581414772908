@import "~assets/styles/1_settings/_settings-project";

.c-invoice-list-table-tbody {
  &__tr {
    border-bottom: 1px solid $gray;
    cursor: pointer;

    &:hover {
      background-color: $light-gray;
    }
  }

  &__td {
    padding: $multiplier-default 0;
    height: 45px;
  }

  &__name {
    padding-left: 20px;
  }

  &__invoice-status {
    font-size: $small-font-size;
    line-height: $small-line-height;
  }

  &__invoice-source {
    margin-left: $multiplier-default * 2;
    font-style: italic;
  }

  &__auto-invoice-source {
    color: $dark-gray;
  }

  &__missing {
    padding: $multiplier-default * 0.5;
    border-radius: $multiplier-default;
    background-color: $light-red;
  }

  &__issued {
    padding: $multiplier-default * 0.5;
    border-radius: $multiplier-default;
    background-color: $light-green;
  }

  &__download {
    color: $light-black;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__download-text {
    margin-left: $multiplier-default * 0.5;
  }

  &__amount {
    padding-right: 20px;
    width: $multiplier-default * 15;
  }

  &__predicted-amount {
    color: $dark-gray;
  }

  &__amount-description {
    color: $dark-gray;
    font-size: $small-font-size;
    line-height: $small-line-height;
  }
}
