@import "~assets/styles/1_settings/_settings-project";

.c-modifications-row {
  &__h4 {
    padding: $multiplier-default 0;
    color: $green;

    &--charge {
      color: $red;
    }
  }

  &__description-row {
    padding-bottom: $multiplier-default;
  }

  &__td {
    border-bottom: 1px solid $gray;
    padding: $multiplier-default;
  }

  &__amount {
    white-space: nowrap;
  }

  &__td-remove {
    width: 1px;
  }

  &__remove {
    position: relative;
    padding: $multiplier-default * 0.5;
    color: red;
    font-size: $multiplier-default * 2;
    cursor: pointer;
  }

  &__modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 999;
    right: 0;
    padding: $multiplier-default * 2;
    background-color: $white;
  }
  &__modal-header {
    white-space: nowrap;
  }

  &__modal-buttons {
    display: flex;
    justify-content: space-between;
  }

  &__modal-button {
    width: 48%;
  }
}
