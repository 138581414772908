@font-face {
  font-family: "Galaxie Copernicus";
  font-style: normal;
  font-weight: 300;
  src: url(~assets/fonts/GalaxieCopernicus-Book.otf) format("truetype");
}

@font-face {
  font-family: "Galaxie Copernicus";
  font-style: normal;
  font-weight: 400;
  src: url(~assets/fonts/GalaxieCopernicus-Book.otf) format("truetype");
}

@font-face {
  font-family: "Galaxie Copernicus";
  font-style: normal;
  font-weight: 700;
  src: url(~assets/fonts/NHaasGroteskDSPro-65Md.otf) format("truetype");
}

@font-face {
  font-family: "NHaas Grotesk Bd";
  font-style: normal;
  src: url(~assets/fonts/NHaasGroteskDSPro-75Bd.otf) format("truetype");
}
