@import "~assets/styles/1_settings/_settings-project";

.c-login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  &__content {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background-color: #f8f8f8;
  }

  &__logo-img {
    width: 130px;
  }

  &__text {
    font-size: 12px;
    line-height: 140%;
    padding: 24px 0;
    text-align: justify;
  }
}
