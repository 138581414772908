@import "~assets/styles/1_settings/_settings-project";

.c-invoice-state {
  display: flex;
  flex-direction: column;
  padding: $multiplier-default * 2;

  &__state {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
  }

  &__info {
    margin-top: $multiplier-default * 2;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    margin-left: $multiplier-default * 2;
    height: $multiplier-default * 2;
    width: $multiplier-default * 2;
    border-radius: 50%;
    background-color: $green;
  }

  &__texts-wrapper {
    display: flex;
  }

  &__texts {
    margin-left: $multiplier-default;
  }

  &__header {
    padding-top: 1px;
  }

  &__sub-header {
    padding-top: $multiplier-default;
  }

  &__download-button {
    width: 50%;
    margin-top: 0;
  }
}
