@import "~assets/styles/1_settings/_settings-project";

.c-buttons-group{
    display: flex;
    justify-content: space-between;
    border: solid 1px $light-black;
    border-radius: $multiplier-default * 0.5;   
    font-size: $small-font-size;
    overflow: hidden;
    user-select: none;
    

    &__button{
        display: flex;
        justify-content: center;
        align-items: center;              
        border-left: solid 1px $light-black;
        white-space: nowrap;
        padding: $multiplier-default $multiplier-default*2;
        cursor: pointer;   
        
        &:first-child{
            border-left: 0;
        }

        &--active{
            color: $white;
            background-color:$light-black;
        }
    }
}
