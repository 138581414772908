@import "~assets/styles/1_settings/_settings-project";

.c-employees-table-tbody {
  &__header {
    border-bottom: 1px solid $gray;
    cursor: default;
    font-weight: 600;
  }

  &__header-td {
    padding: $multiplier-default 0;
  }

  &__tr {
    border-bottom: 1px solid $gray;
    cursor: pointer;

    &:hover {
      background-color: $light-gray;
    }
  }

  &__td {
    padding: $multiplier-default 0;
  }

  &__invoice-status {
    font-size: $small-font-size;
    line-height: $small-line-height;
  }

  &__missing {
    padding: $multiplier-default * 0.5;
    border-radius: $multiplier-default;
    background-color: $light-red;
  }

  &__issued {
    padding: $multiplier-default * 0.5;
    border-radius: $multiplier-default;
    background-color: $light-green;
  }

  &__start-date {
    display: flex;
    align-items: center;
  }

  &__date-day {
    position: relative;
    padding-right: $multiplier-default*2;
  }

  &__date-nth {
    position: absolute;    
    top: -$multiplier-default * 0.25;
    font-size: $small-font-size;    
  }

  &__tenure {
    padding-left: $multiplier-default*2;
    font-style: italic;
  }
}
