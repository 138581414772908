@import "~assets/styles/1_settings/_settings-project";

.c-add-benefit-form {
  &__page-first {
    padding-right: $multiplier-default * 0.5;
    padding-bottom: $multiplier-default;
  }

  &__page-second {
    padding-left: $multiplier-default * 0.5;
    padding-bottom: $multiplier-default;
  }
}
