@import "~assets/styles/1_settings/_settings-project";

.c-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $multiplier-default $multiplier-default * 5;
  padding-bottom: 0;

  &__logo {
    height: $multiplier-default * 3;
  }

  &__logo-img {
    height: 100%;
  }
  
  &__right-items{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}