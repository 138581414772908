@import "~assets/styles/1_settings/_settings-project";

.c-sync-with-timepot {
    margin-left: $multiplier-default*5;

    &__button{
        padding: $multiplier-default*1 $multiplier-default*2;
        margin: 0;
        white-space: nowrap;
        line-height: $default-line-height;
        font-size: $default-font-size;
    }
}
