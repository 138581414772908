@import "~assets/styles/1_settings/_settings-project";

.c-employees-table-header {
  
  &__tr {
      border-bottom: 1px solid $gray;
      
  }

  &__th {
    font-size: $small-font-size;
    line-height: $small-line-height;
    font-weight: $font-regular;
    padding: $multiplier-default 0;
  }

  &__name{
    width: 300px;
  }
}
