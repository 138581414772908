@import "~assets/styles/1_settings/_settings-project";

.c-hide-sensitive-data {
  position: relative;
  cursor: pointer;
  text-align: right;
  min-width: $multiplier-default * 5;

  &__label {
    position: absolute;
    z-index: 999999;
    left: 140%;
    top: 50%;
    transform: translateY(-50%);
    padding: $multiplier-default * 0.5;
    border-radius: $multiplier-default * 0.25;
    color: $white;
    background-color: $black;
    white-space: nowrap;
    font-weight: $font-regular;
    font-size: $small-font-size;
    line-height: $small-line-height;

    &::before {
      position: absolute;
      z-index: -1;
      top: 3px;
      left: -$multiplier-default;
      transform: rotate(45deg);
      content: "";
      height: $multiplier-default * 2;
      width: $multiplier-default * 2;

      background-color: $black;
    }
  }
}
