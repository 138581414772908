@import "~assets/styles/1_settings/_settings-project";

.c-employee-company-data {
  &__user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: $multiplier-default * 2;
  }

  &__user-icon {
    height: $multiplier-default * 8;
    width: $multiplier-default * 8;
    margin-right: $multiplier-default * 2;
    border-radius: 50%;
    background-color: $gray;
  }
  &__user-name {
  }
}
