@import "~assets/styles/1_settings/_settings-project";

.c-number-input {
  $self: &;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $multiplier-default;

  &__label {
    padding-left: $multiplier-default;
    padding-bottom: $multiplier-default * 0.5;
    font-size: $small-font-size;
    line-height: $small-line-height;
  }

  &__input {
    width: 100%;
    padding: $multiplier-default * 0.5;
    border: 1px solid $gray;
    border-radius: $multiplier-default * 0.25;

    #{$self}--error & {
      border: 1px solid $red;
    }
  }

  &__error {
    margin-top: $multiplier-default * 0.5;
    height: $multiplier-default;
    font-size: $small-font-size;
    line-height: $multiplier-default;
    color: $red;
  }
}
