@import "~assets/styles/1_settings/_settings-project";

.c-month-picker {
  display: flex;
  justify-content: center;

  &__month-label {
    position: relative;
    min-width: $multiplier-default * 30;
    font-family: $font-family-bold;
    font-size: $multiplier-default * 3;
    text-align: center;
    line-height: $multiplier-default * 4;
    cursor: pointer;
    transition: $transition-default;

    &:hover{
      color: $light-black;
    }
  }

  &__previous-month-arrow,
  &__next-month-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $multiplier-default * 0.25;
    height: $multiplier-default * 4;
    width: $multiplier-default * 4;
    background-color: $primary-black;
    box-shadow: $box-shadow-default;
    transition: $transition-default;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: $light-black;
    }
  }

  &__arrow-disabled {
    background-color: $dark-gray;
    cursor: unset;

    &:hover {
      background-color: $dark-gray;
    }
  }
}
