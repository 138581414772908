@import "~assets/styles/1_settings/_settings-project";

.c-text-area-input {
  $self: &;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: $multiplier-default * 4;

  

  &__description {
    font-size: $small-font-size;
    padding-top: $multiplier-default;
  }

  &__input {
    width: 100%;
    font-family: inherit;
    margin-top: $multiplier-default;
    padding: $multiplier-default;
    border: 1px solid $gray;
    border-radius: $multiplier-default * 0.25;
    height: $multiplier-default * 9.5;
    resize: none;

    #{$self}--error & {
      border: 1px solid $red;
    }
  }
}
