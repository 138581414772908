@import "~assets/styles/1_settings/_settings-project";

.c-invoice-summary {
  display: flex;
  flex-direction: column;
  padding: 0 $multiplier-default * 2;

  &__buttons {
    width: 100%;
    display: flex;
  }

  &__button {
    margin-right: $multiplier-default * 2;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
