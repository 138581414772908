@import "~assets/styles/1_settings/_settings-project";

.c-checkbox {
  $self: &;
  display: flex;
  align-items: center;
  padding: $multiplier-default * 0.25 0;
  width: 100%;
  cursor: pointer;
  transition: $transition-default;

  &__label {
    padding-left: $multiplier-default * 0.5;
    font-size: $small-font-size;
    line-height: $small-line-height;
    user-select: none;
  }

  &__square {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $multiplier-default * 2;
    width: $multiplier-default * 2;
    border-radius: $multiplier-default * 0.25;
    border: solid 1px $gray;
    background-color: $white;
    color: $white;
    font-size: $small-font-size;

    #{$self}--checked & {
      border: unset;
      background-color: $light-black;
    }
  }
}
