@import "~assets/styles/1_settings/_settings-project";

.c-select-input {
  $self: &;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $multiplier-default;
  cursor: pointer;

  &--disabled {
    cursor: unset;
  }

  &__wrapper {
    position: relative;
  }

  &__input {
    width: 100%;
    padding: $multiplier-default;
    padding-right: $multiplier-default * 2.5;
    border: 1px solid $gray;
    border-radius: $multiplier-default * 0.25;
    text-overflow: ellipsis;
    cursor: pointer;

    #{$self}--error & {
      border: 1px solid $red;
    }

    &:disabled {
      background-color: $black;
      opacity: 0.1;
      cursor: unset;

      &::placeholder {
        color: $white;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__input-icon {
    position: absolute;
    top: $multiplier-default;
    right: $multiplier-default;

    #{$self}--disabled & {
      opacity: 0.1;
    }
  }

  &__options-wrapper {
    position: relative;
    width: 100%;
  }

  &__options {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    box-shadow: $box-shadow-default;
    background-color: $white;
  }

  &__option {
    padding: $multiplier-default;
    border-bottom: 1px solid $gray;
    white-space: nowrap;

    &:hover {
      background-color: $gray;
    }
  }
}
