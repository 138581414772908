@import "~assets/styles/1_settings/_settings-project";

.c-benefits-table {
  position: relative;
  width: 100%;
  margin-top: $multiplier-default * 2;

  &__th{
    width: 50%;
    padding: 0 $multiplier-default;
    font-size: $small-font-size;
    line-height: $small-line-height;
    font-weight: $font-regular;
  }

  &__td{
    padding: $multiplier-default;
  }

  &__divider {
    height: $multiplier-default;

    &-td {
      position: absolute;
      width: 100%;
      height: 1px;
      margin-top: $multiplier-default * 0.5;
      background-color: $gray;
    }
  }
}
