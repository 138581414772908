@import "~assets/styles/1_settings/_settings-project";

.c-total-amount {
  position: relative;
  padding-top: $multiplier-default * 2;
  
  &__h3 {
    position: absolute;
    top: $multiplier-default * 4;

    height: 0;
  }

  &__amount-gross{
    font-size: 15px;
  }
}
