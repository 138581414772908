@import "~assets/styles/1_settings/_settings-project";
@import "~sass-mq";

.c-employee-invoice-route {
    display: flex;
    padding: $multiplier-default * 2 $multiplier-default * 5;
    flex-direction: column;

    &__month-picker-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $multiplier-default * 2;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include mq($from: tablet) {
            flex-direction: row;
        }
    }

    &__back-arrow {
        $arrow: &;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $multiplier-default * 0.25;
            height: $multiplier-default * 4;
            width: $multiplier-default * 4;
            background-color: $primary-black;
            box-shadow: $box-shadow-default;
            transition: $transition-default;
            color: $white;
        }

        span {
            padding-left: $multiplier-default;
            &::first-letter {
                text-transform: uppercase;
            }
        }

        &:hover {
            #{$arrow}-icon {
                background-color: $light-black;
            }
        }
    }

    &__status {

        &--missing {
            padding: $multiplier-default;
            border-radius: $multiplier-default;
            background-color: $light-red;
          }
        
          &--issued {
            padding: $multiplier-default;
            border-radius: $multiplier-default;
            background-color: $light-green;
          }
    }

    .c-invoice-summary,
    .c-invoice-state {
        display: none;
    }
}
