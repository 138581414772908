@import "~assets/styles/1_settings/_settings-project";

.c-search {
  &__input {
    font-family: $font-family;
    padding: $multiplier-default + 1 $multiplier-default * 2;
    padding-right: $multiplier-default * 12;
    border: solid 1px $gray;
    border-radius: $multiplier-default * 0.25;
  }
}
