@import "~assets/styles/1_settings/_settings-project";

.c-employees-table {
  width: 100%;

  &__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $multiplier-default;
    background-color: $very-light-black;
  }
}
