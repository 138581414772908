@import "~assets/styles/1_settings/_settings-project";

.c-company-data-form{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $multiplier-default*2;

    &__header{
        padding-bottom: 20px;
    }
}