@import "~assets/styles/1_settings/_settings-project";

.c-invoice-list-summary {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: $light-gray;
  border-top: solid 2px $primary-black;
  padding: $multiplier-default 20px;
  margin-top: $multiplier-default * 3;

  &__left-items {
    display: flex;
    align-items: center;
  }

  &__right-items {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    justify-content: flex-end;
  }

  &__predicted-row {
    opacity: 0.5;
    padding-top: $multiplier-default;
  }

  &__row-label {
    display: flex;
  }

  &__icon {
    color: $green;
    padding-right: $multiplier-default;
  }

  &__total-row-label {
    font-weight: $font-bold;
  }

  &__download-button {
    width: unset;
    height: $multiplier-default * 4;
    padding-right: $multiplier-default;
    margin-right: $multiplier-default * 3;
  }

  &__amount {
    width: $multiplier-default * 15;
  }

  &__amount-gross {
    font-weight: $font-bold;
    font-size: $big-font-size;
    line-height: $big-font-size;
    color: $primary-black;
  }

  &__amount-description {
    font-size: $small-font-size;
    line-height: $small-line-height;
  }
}
