@import "~assets/styles/1_settings/_settings-project";

.c-switch {
  $self: &;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: $multiplier-default * 0.25 0;
  cursor: pointer;

  &__texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: $multiplier-default;
  }

  &__label {
    user-select: none;
  }

  &__description {
    font-size: $small-font-size;
    padding-top: $multiplier-default;
  }

  &__wrapper-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    min-width: $multiplier-default * 3;
    border-radius: 6px;
    border: solid 1px $light-black;
    margin-top: $multiplier-default * 0.25;
    color: $white;

    transition: $transition-default;

    #{$self}--checked & {
      border: unset;
      background-color: $light-black;
    }
  }

  &__circle {
    height: 6px;
    width: 6px;
    border-radius: 3px;
    transform: translateX(-6px);
    background-color: $light-black;

    transition: $transition-default;

    #{$self}--checked & {
      background-color: $white;
      transform: translateX(6px);
    }
  }
}
