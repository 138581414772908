@import "~assets/styles/1_settings/_settings-project";

.c-companies {
  width: 100%;
  padding: $multiplier-default * 2;
  padding-bottom: $multiplier-default * 3;
  border-radius: $default-border-radius;
  box-sizing: content-box;
  &__h3 {
    padding-bottom: $multiplier-default * 2;
  }
}
