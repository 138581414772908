.c-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  &__h3{
      padding-top: 30px;
  }
}
