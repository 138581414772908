@import "~assets/styles/1_settings/_settings-project";
@import "~sass-mq";

.c-invoice-route {
  display: flex;
  padding: $multiplier-default * 2 $multiplier-default * 5;
  flex-direction: column;

  &__month-picker-wrapper {
    width: 100%;
    margin-bottom: $multiplier-default * 2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mq($from: tablet) {
      flex-direction: row;
    }
  }
}
