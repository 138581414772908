@import "~assets/styles/1_settings/_settings-project";

.c-settings {
  display: flex;
  margin-right: $multiplier-default * 3;

  &__icon {
    padding: $multiplier-default * 0.5;
    border-radius: 50%;
    transition: $transition-default;
    cursor: pointer;

    &:hover {
      background-color: $dark-gray;
      color: $light-black;
    }
  }
}
