/*------------------------------------*\
  #PROJECT VARIABLES
\*------------------------------------*/

// Colors
$accent-black: rgba(0, 0, 0, 0.1);
$very-light-black: rgba(0, 0, 0, 0.2);
$info-blue: #5AC8FA;
$primary-black: #000000;

$light-white: rgba(255, 255, 255, 0.5);
$white: #fff;
$light-black: rgba(74, 74, 74, 0.5);
$black: #4a4a4a;
$light-green: rgba(40, 189, 66, 0.2);
$green: #28bd42;
$light-red: rgba(230, 12, 0, 0.2);
$red: #e60c00;
$orange: #ff9500;
$light-gray: #f8f8f8;
$gray: rgba(74, 74, 74, 0.1);
$dark-gray: #c4c4c4;

$text-default-color: $black;
$text-light-color: $white;

$fader-background: rgba(74, 74, 74, 0.25);

// Border
$default-border-radius: 4px;

// Fonts
$font-family: "Galaxie Copernicus", sans-serif;
$font-family-bold: "NHaas Grotesk Bd", sans-serif;

// Font
$font-regular: 400;
$font-bold: 700;
$big-font-size: 17px;
$big-line-height: 21px;
$default-font-size: 13px;
$default-line-height: 17px;
$small-font-size: 11px;
$small-line-height: 15px;

// Spacing
$multiplier-default: 8px;

// Widths
$site-min-width: 1024px;
$site-max-width: 1160px;

//Transition
$transition-default: all 300ms ease;

// Box shadow
$box-shadow-default: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
$box-shadow-lvl-1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$box-shadow-lvl-2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$box-shadow-lvl-3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
$box-shadow-lvl-4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
$box-shadow-lvl-5: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
$box-shadow-hover: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
