@import "~assets/styles/1_settings/_settings-project";

.c-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $fader-background;

  &__content {
    background-color: $white;
    max-width: 500px;
    border-radius: $multiplier-default * 0.5;
    box-shadow: $box-shadow-default;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $multiplier-default * 3;
    padding-bottom: 0;
  }

  &__header-close {
    position: absolute;
    right: $multiplier-default * 2;
    top: $multiplier-default * 2;
    font-size: $multiplier-default * 3;
    cursor: pointer;
  }

  &__header-h3 {
    color: $black;
    font-size: $multiplier-default * 2.5;
    line-height: $multiplier-default * 3;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__children {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: $multiplier-default * 2 0;
    
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $multiplier-default * 3;
  }

  &__footer-button {
    width: 48%;
  }

  &__accept-button {
    background-color: $primary-black;
  }
}
