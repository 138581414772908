@import "~assets/styles/1_settings/_settings-project";

.c-fader{
    position: fixed;
    top: 0;    
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray;

    &__lds-ellipsis {
        display: inline-block;
        position: relative;
        width: $multiplier-default*10;
        height: $multiplier-default*10;
      }
      &__lds-ellipsis div {
        position: absolute;
        top: $multiplier-default*5;
        width: $multiplier-default*1.5;
        height: $multiplier-default*1.5;
        border-radius: 50%;
        background: $black;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      &__lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      &__lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      &__lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      &__lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }
      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(24px, 0);
        }
      }
}