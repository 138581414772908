@import "~assets/styles/1_settings/_settings-project";

.c-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-bold;

  &__item {
    padding-right: $multiplier-default * 3;
    cursor: pointer;
    transition: $transition-default;

    &--active {
      font-family: $font-family-bold;
    }
  }
}
