@import "~assets/styles/1_settings/_settings-project";

.c-benefits-row {
  &__td {
    padding: $multiplier-default;
  }

  &__td-remove{
    width: 1px;
  }

  &__remove {
    position: relative;
    padding: $multiplier-default;
    color: $red;
    font-size: $multiplier-default * 2;
    cursor: pointer;
  }

  &__remove-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 999;
    padding: $multiplier-default * 2;
    background-color: $white;
  }

  &__remove-modal-header {
    white-space: nowrap;
  }

  &__remove-modal-buttons {
    display: flex;
    justify-content: space-between;
  }

  &__remove-modal-button {
    width: 48%;
  }
}
