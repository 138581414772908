@import "~assets/styles/1_settings/_settings-project";

.c-benefits-management {
  padding: $multiplier-default * 2;

  &__no-benefits {
      font-weight: $font-bold;
      padding-top: $multiplier-default * 3;
  }

}
