@import "~assets/styles/1_settings/_settings-project";

.c-divider {
  width: 100%;
  padding-bottom: $multiplier-default;
  border-top: 1px solid $gray;

  &--blue {    
    border-color: $primary-black;
  }
}
