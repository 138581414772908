@import "~assets/styles/1_settings/_settings-project";

.c-add-modification-form {
  background-color: $white;
  padding: $multiplier-default;
  margin-top: $multiplier-default*2;

  &__header{
      padding-bottom: $multiplier-default*2;
  }

  &__page-first {
    padding-right: $multiplier-default * 0.5;
    padding-bottom: $multiplier-default;
    display: flex;
    align-items: flex-end;
  }

  &__page-second {
    padding-left: $multiplier-default * 0.5;
    padding-bottom: $multiplier-default;
  }

  &__button{
    width: $multiplier-default*9;  
  }
}
