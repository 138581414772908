@import "~assets/styles/1_settings/_settings-project";

.c-invoice-details {
  padding: $multiplier-default * 2;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - #{$multiplier-default * 20});

  &__h3 {
    padding-bottom: $multiplier-default;
  }

  &__field {
    width: unset;
  }

  &__content {
    overflow-y: auto;
  }

  &__sign {
    align-self: flex-end;
    padding: $multiplier-default 0;
    padding-bottom: 22px;
  }
}
