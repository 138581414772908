@import "~assets/styles/1_settings/_settings-project";
@import "~sass-mq";

.c-page {
  width: 100%;

  &--third{
    width: 33.33%;
  }

  &--gray {
    background-color: $light-gray;
  }

  @include mq($from: tablet) {
    width: 50%;
  }
}
