@import "~assets/styles/1_settings/_settings-project";

.c-upload-invoice-modal {
  &__file-input {
    display: none;
  }

  &__error {
    padding-top: $multiplier-default * 2;
    color: $red;
  }

  &__content {
    padding: 0 $multiplier-default;
  }
}
