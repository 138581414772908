/*------------------------------------*\
  #UTILITIES
\*------------------------------------*/

.u-align-left{
  text-align: left;
}

.u-align-right{
  text-align: right;
}

.u-bold{
  font-weight: $font-bold;
}