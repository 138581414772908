@import "~assets/styles/1_settings/_settings-project";

.c-info {
  $c: &;
  display: flex;
  background-color: $very-light-black;
  padding: $multiplier-default;
  margin-top: $multiplier-default;
  white-space: pre-line;
  color: $black;

  &__icon {
    padding-right: $multiplier-default;
    font-size: $multiplier-default * 2;
    color: $info-blue;
  }

  &--gray {
    background-color: $light-gray;

    #{$c}__icon {
      color: $black;
    }
  }
}
